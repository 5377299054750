 .homePageWindow {
    display: flex;
    flex-direction: column;
    max-width: 70vw;
    justify-content: center;

 }

 .formBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 }

 .formButton {
    width: 20vw;
 }

 .bottomBar {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   margin: 30px;
 }

 .infoTab {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
   border: 2px solid #DEEAF3;
   box-sizing: border-box;
   border-radius: 20px;
   margin-top: 20px;
   margin-right: 20px;
   padding: 20px;
   width: 20vw;
 }

 .infoTabIcon {
   background-color: #F6FBFF;
   border-radius: 100px;
   width: 100px;
   height: 100px;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin: 20px;
 }

 .infoTabText {
  text-align: center;
 }

 .contactusButton {
  width: 20vw;
  align-self: center;
  height: 50px;
  font-size: 18px;
  font: Manrope;
 }