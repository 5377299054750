* {
    margin: 0;
    padding: 0;
} 

.window {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

 button {
    margin: 10px;
    background-color: #4BAAFC;
    border-radius: 10px;
    border: 0 solid black;
    padding: 10px;
    color: white;
    cursor: pointer;
    font: Manrope;
 }

p, label, li {
	font-family: Arial;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
}

textarea {
	padding-bottom: 50px;
}

.vflexbox {
	display: flex;
	flex-direction: column;
}

.hflexbox {
	display: flex;
	flex-direction: row;
}

h1 {
	font-family: Arial;
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	text-align: center;
	color: #373737;
}

h2 { 
	font-family: Arial;
	font-size: 19.5px;
	font-weight: 900;
	text-decoration: underline;
}

h3 {
	font-family: Arial;
	font-weight: 400;
	font-size: 12px;
	color: gray;
}

h4 {
	font-family: Arial;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	text-align: center;
	color: #8F8F8F;
}

 input {
   margin-bottom: 30px;
   width: 400px;
   height: 30px;
   font-family: Manrope;
	font-weight: 400;
	font-size: 12px;
	padding-left: 8px;
 }

 .centerForm {
 	display: flex;
 	flex-direction: column;
 	align-items: center;
 }

 mark1 {
 	text-decoration: underline;
 	background-color: yellow;
 }

 mark2 {
 	background-color: #9fc5e8;
 	text-decoration: underline;
 }

 li {
	margin-left: 53px;
	font-size: 13px;
	line-height: 20px;
}

ul {
	display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}