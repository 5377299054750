.headerBarContainer {
  display: flex;
  border-bottom: #4CAAFC 3px solid;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  width: 100vw;
}

.logoImage {
  width: 160px;
  height: 50px;
  padding: 10px 0px 10px 20px;
  cursor: pointer;
}

.logoIcon {
  width: 40px;
  height: 40px;
  padding: 10px;
  margin-bottom: 10px;
}

.footer {
  display: flex;
  flex-direction: column;
  background-color: black;
  align-items: center;
}

.disclosure {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #D9DBE1;
} 

.getStartedButton {
  margin: 10px 20px 10px 0px;
}
