.formContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;

}

.formBox {
display: flex;
flex-direction: row;
justify-content: space-between;
}

.formButton {
  width: 10vw;
}

.formFieldContainer {
 display: flex;
 flex-direction: row;
 align-items: center;
}

.formPlaceholderText {
 font-size: 14px;
 margin-bottom: 30px;
 padding-left: 10px;
 color: #828282;
}

.clientButtons {
  padding: 10px;
  border: 1px solid grey;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, 12rem);
  grid-gap: 0.5rem;
}

.subtitle {
  font-size: 16px;
  padding-top: -5px;
  font-weight: normal;
  text-decoration: none;
  color: grey;
}

.title {
  margin-bottom: 5px;
}